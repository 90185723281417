<script setup lang="ts">
import LogoMas4you from './logo/Mas4you.vue'
import LogoMas4youDark from './logo/Mas4youDark.vue'
import stagedoosLogo from '/public/img/stagedoos/logo-icon.svg'

const props = defineProps<{
  colorMode?: 'dark' | 'light'
}>()

const currentColorMode = useColorMode()
const colorMode = computed(() => props.colorMode || currentColorMode.value)

const { tenant } = useTenant()
const { appName } = useAppName()

// Make sure these components exist in the components/public/logo folder
const logoComponents: Record<string, Component> = {
  mas4you: LogoMas4you,
}

const logoComponentsDark: Record<string, Component> = {
  mas4you: LogoMas4youDark,
}

// Dynamically import the logo component based on the name
const selectedLogoComponent = computed(() => {
  const isDark = colorMode.value === 'dark'
  return isDark ? logoComponentsDark[tenant.value] : logoComponents[tenant.value]
})

// TODO: fix returning null, we want to get rid of this line
// Dynamically create component paths and imports based on tenant
// const selectedLogoComponent = computed(() => {
//   if (tenant.value == 'mas4you') {
//     const componentName = 'mas4you'
//     const isDark = colorMode.value === 'dark'
//     const componentPath = isDark
//       ? `./logo/${componentName}Dark.vue`
//       : `./logo/${componentName}.vue`
//     return defineAsyncComponent(() => import(componentPath))
//   }

// TODO: get rid of this line
// if (tenant.value != 'mas4you') return null
// if (!tenant.value) return null

// const componentName = capitalize(tenant.value)

// try {
//   console.log('componentPath', componentPath)
//   return defineAsyncComponent(() => import(componentPath).catch(() => {
//     console.warn(`Could not load logo component: ${componentPath}`)
//     return null
//   }))
// }
// catch (error) {
//   console.warn(`Error loading logo component: ${componentPath}`, error)
//   return null
// }
// })
</script>

<template>
  <div class="relative mr-4 mt-2 font-semibold">
    <component
      :is="selectedLogoComponent"
      v-if="selectedLogoComponent"
      class="max-h-full"
    />
    <div
      v-else
      class="flex items-center gap-2"
    >
      <img
        :src="stagedoosLogo"
        class="h-6"
        alt="Stagedoos logo"
      >
      <h1>
        {{ appName }}
      </h1>
    </div>
  </div>
</template>
