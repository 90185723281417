<script setup lang="ts">
const providerTypeName = useOrganizationTypeName('Provider')

const { tenant } = useTenant()
const { slogan } = useTenantConfig()

const showMenu = computed(() => tenant.value === 'mas4you')

const columns = [
  {
    name: 'Leerlingen',
    items: [
      { name: 'Inloggen', to: '/inloggen' },
      // { name: 'Stages', to: '/' },
      // { name: 'Informatie', to: '/over-ons' },
      // { name: 'Contact', to: '/contact' },
    ],
  },
  {
    name: 'Ouders',
    items: [
      // { name: 'Stages', to: '/' },
      { name: 'Informatie', to: '/ouders' },
      // { name: 'Contact', to: '/contact' },
    ],
  },
  {
    name: 'Organisaties',
    items: [
      { name: `Registeren als ${providerTypeName}`, to: '/organizations/signup' },
      // { name: 'Stages', to: '/' },
      // { name: 'Informatie', to: '/over-ons' },
      // { name: 'Contact', to: '/contact' },
    ],
  },
]

// TODO: we really need a 'slug' for an identifier.
const { findSystemPageById } = useSystemPages()
const signupPage = findSystemPageById(2)

const ctaButtons = [
  // { title: 'Inloggen', to: '/inloggen' },
  { title: `Registeren als ${providerTypeName}`, to: signupPage?.path || '#' },
]

const hasDarkBackground = computed(() => tenant.value == 'oldebroek' || tenant.value == 'mas4you')

</script>

<template>
  <FooterMaud
    style="--color-theme-footer-background: #02B2FE;"
    :menu-columns="showMenu ? columns : []"
    :slogan="slogan"
    :cta-buttons="ctaButtons"
    :has-dark-background="hasDarkBackground"
    logo-class="max-h-16"
    :powered-by="{
      text: 'Powered by Stagedoos',
      to: 'https://www.stagedoos.nl',
    }"
  />
</template>

<style scoped>
/* TODO: add to generic styles? It's only in use for the footer currently. */
/* or maybe make it the info color? */

.bg-tertiary-500 {
  background-color: #02B2FE;
}
</style>
