<script setup lang="ts">
const colorMode = useColorMode()

import darkLogo from '/public/img/mas4you/logo-white.svg'
import lightLogo from '/public/img/mas4you/logo.svg'

const darkSrc = darkLogo
const lightSrc = lightLogo

const isDark = useDark()
const logoSrc = computed(() =>
  colorMode.value === 'dark' ? darkSrc : lightSrc,
)
</script>

<template>
  <img
    :src="logoSrc"
    :alt="isDark ? 'Mas4you logo (donker)' : 'Mas4you logo'"
  >
</template>
